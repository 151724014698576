<template>
  <div class="student-profile-container font-menu-medium">
    <div class="family-avatar">
      <div
        class="avatar-item font-menu-medium"
        v-for="(item, index) in stuInfoList"
        :key="index"
        @click="changeCurrentStu(index)"
        :class="{ activeBgc: index === currentId }"
      >
        <div class="image" v-if="!item.photo">
          <img
            src="@/assets/img/05-Panda/panda-boy.svg"
            alt=""
            v-if="item.gender === 0"
          />
          <img src="@/assets/img/05-Panda/panda-girl.svg" alt="" v-else />
        </div>
        <div class="image" v-else>
          <img :src="item.photo" alt="" />
        </div>
        <div class="name">{{ item.firstName }}</div>
      </div>
    </div>
    <div class="student-data">
      <el-form
        ref="personalInfo"
        :model="currentStuInfo"
        label-width="130px"
        label-position="left"
        class="stu-form"
      >
        <el-form-item label="First Name" class="font-menu-medium">
          <el-input
            v-model="currentStuInfo.firstName"
            placeholder="Your first name"
          ></el-input>
        </el-form-item>

        <el-form-item label="Last Name" class="font-menu-medium">
          <el-input
            v-model="currentStuInfo.lastName"
            placeholder="Your last name"
          ></el-input>
        </el-form-item>

        <el-form-item label="Birthday">
          <!-- <el-input
            v-model="currentStuInfo.birthday"
            placeholder="Your Birthday"
          ></el-input> -->
          <el-date-picker
            v-model="currentStuInfo.birthday"
            type="date"
            range-separator=""
            default-value
            popper-class="pick-date-range"
          >
          </el-date-picker>
        </el-form-item>

        <!-- v-model="currentStuInfo.gender" -->
        <el-form-item label="Gender">
          <div class="gender-box">
            <div
              class="gender girl"
              :class="{ opacity: currentStuInfo.gender === 1 }"
              @click="currentStuInfo.gender = 1"
            >
              GIRL
            </div>
            <div
              class="gender boy"
              :class="{ opacity: currentStuInfo.gender === 0 }"
              @click="currentStuInfo.gender = 0"
            >
              BOY
            </div>
          </div>
        </el-form-item>

        <!-- <el-form-item label="Language">
          <el-select
            v-model="currentStuInfo.language"
            style="width: 100%"
            placeholder="Home Language is not Mandarin Chinese"
          >
            <el-option label="English" value="english"></el-option>
            <el-option label="Chinese" value="chinese"></el-option>
            <el-option
              label="Home Language is not Mandarin Chinese"
              value="notChinese"
            ></el-option>
          </el-select>
        </el-form-item> -->

        <el-form-item label="Special Needs">
          <el-input
            type="textarea"
            v-model="currentStuInfo.specialNeeds"
            :autosize="{ minRows: 3, maxRows: 4 }"
            placeholder="Type..."
          ></el-input>
        </el-form-item>
      </el-form>
      <div class="stu-avatar">
        <div class="avatar-box">
          <div class="avatar-content">
            <div class="avatar-img-wrapper">
              <span class="overlay"></span>

              <div class="avatar-img" v-if="!currentStuInfo.photo">
                <img
                  src="@/assets/img/05-Panda/panda-boy.svg"
                  alt=""
                  v-if="currentGender === 0"
                />
                <img src="@/assets/img/05-Panda/panda-girl.svg" alt="" v-else />
              </div>
              <el-upload
                class="avatar-img"
                :action="`${testUrl}/KidsUser/uploadKidsUserPieture`"
                :headers="headers"
                :on-success="handleAvatarSuccess"
                :data="uploadData"
                :before-upload="beforeAvatarUpload"
              >
                <img :src="currentStuInfo.photo" />
                <!-- <i v-else class="el-icon-plus avatar-uploader-icon"></i> -->
              </el-upload>

              <div class="open-camera">
                <span class="overlay"></span>
                <img src="@/assets/img/18-Account/icon-camera.svg" alt="" />
              </div>
            </div>
          </div>
          <div class="explain">Profile Picture</div>
        </div>
        <div class="gender-flag" :class="{ 'girl-flag': currentGender === 1 }">
          <div class="boy" v-show="currentGender === 0">BOY</div>
          <div class="girl" v-show="currentGender === 1">GIRL</div>
        </div>
        <!-- <div class="delete font-menu-medium" @click="deleteCurrentStu">
          Delete
        </div> -->
      </div>
    </div>
    <div class="button-area font-menu-medium">
      <div class="cancle-btn btn">Cancel</div>
      <div class="save-btn btn" @click="submitPersonalInfo">Save</div>
    </div>
  </div>
</template>

<script>
import {
  updateKidsUserInfo,
  changeCurrentKid,
} from "@/assets/js/axios/kidsApi.js";
export default {
  name: "StudentProfile",
  created() {
    const { userSetting } = JSON.parse(localStorage.getItem("userData"));
    const { familyMember } = JSON.parse(localStorage.getItem("userData"));

    if (this.$store.state.dashboard.isKid) {
      this.stuInfoList.push(userSetting);
    } else {
      const kidsArr = familyMember.filter((item) => item.isKids);
      // console.log(kidsArr, "++++++++++++++++++++++");

      this.stuInfoList = kidsArr;
    }

    this.stuInfoList.forEach((item) => {
      item.birthday = item.birthday ? item.birthday.split("T")[0] : "";
    });

    this.currentStuInfo = this.stuInfoList[0];
    this.currentStuInfo.birthday = this.currentStuInfo.birthday
      ? this.currentStuInfo.birthday.split("T")[0]
      : "";

    const { token, refreshToken } = JSON.parse(
      localStorage.getItem("userData")
    );
    this.headers.token = token;
    this.headers.refreshToken = refreshToken;
  },
  data() {
    return {
      outerVisible: false,
      innerVisible: false,
      currentStuInfo: {},
      currentId: 0,
      currentGender: 0,
      stuInfoList: [],
      headers: {},
      // testUrl: "https://testapi.nihaocafe.com/SmartLingo",
      testUrl: "https://api.nihaocafe.com/SmartLingo",
    };
  },
  computed: {
    kidUserId() {
      return this.$store.state.dashboard.kidUserId;
    },
    uploadData() {
      const userId = this.$store.state.dashboard.isKid
        ? this.currentStuInfo.userId
        : this.currentStuInfo.id;
      return { userId };
    },
  },
  methods: {
    async submitPersonalInfo() {
      const sendData = {
        userId: this.$store.state.dashboard.isKid
          ? this.currentStuInfo.userId
          : this.currentStuInfo.id,
        firstName: this.currentStuInfo.firstName,
        lastName: this.currentStuInfo.lastName,
        birthday: this.currentStuInfo.birthday,
        gender: this.currentStuInfo.gender,
        specialNeeds: this.currentStuInfo.specialNeeds,
      };
      const result = await updateKidsUserInfo(sendData);
      if (result.code === 200) {
        this.$message({
          message: "Modified successfully",
          type: "success",
        });
        this.initUserProfile();
      } else {
        this.$message({
          message: "Modified failed",
          type: "error",
        });
      }
    },
    changeCurrentStu(index) {
      this.currentStuInfo = this.stuInfoList[index];
      this.currentId = index;
      this.currentGender = this.stuInfoList[index].gender;
    },
    handleAvatarSuccess(res, file) {
      // console.log(URL.createObjectURL(file.raw));
      this.currentStuInfo.photo = URL.createObjectURL(file.raw);
      // console.log(this.$store.state.dashboard.isKid);

      if (res.code === 200) {
        this.initUserProfile();
      }
    },
    async initUserProfile() {
      const sendData = {
        id: this.$store.state.dashboard.isKid
          ? this.currentStuInfo.userId
          : this.currentStuInfo.id,
      };
      const result = await changeCurrentKid(sendData);
      // console.log(result);
      if (result && result.code === 200) {
        localStorage.userData = JSON.stringify(result.data);
        this.$store.commit("setUserInfo", result.data);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    },
    // 图片校验
    beforeAvatarUpload(file) {
      // const isJPG = file.type === "image/jpeg";
      // const isLt2M = file.size / 1024 / 1024 < 2;
      // if (!isJPG) {
      //   this.$message.error("上传头像图片只能是 JPG 格式!");
      // }
      // if (!isLt2M) {
      //   this.$message.error("上传头像图片大小不能超过 2MB!");
      // }
      // return isJPG && isLt2M;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>

