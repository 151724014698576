<template>
  <div class="personal-information-container">
    <div class="personal-info-area">
      <div class="personal-info font-menu-medium">
        <el-form
          ref="personalInfo"
          :model="currentProfileInfo"
          label-width="100px"
          label-position="left"
          placeholder="Your first name"
        >
          <el-form-item label="First Name">
            <el-input
              v-model="currentProfileInfo.firstName"
              class="name font-menu-medium"
            ></el-input>
          </el-form-item>

          <el-form-item label="Last Name">
            <el-input
              v-model="currentProfileInfo.lastName"
              placeholder="Your last name"
            ></el-input>
          </el-form-item>

          <el-form-item label="Email">
            <el-input
              v-model="currentProfileInfo.email"
              class="email"
              disabled
            ></el-input>
          </el-form-item>

          <el-form-item label="Phone">
            <el-input
              v-model="currentProfileInfo.phone"
              placeholder="Your Phone Number"
            ></el-input>
          </el-form-item>

          <el-form-item label="Wechat">
            <el-input
              v-model="currentProfileInfo.weChat"
              placeholder="Your Wechat ID"
            ></el-input>
          </el-form-item>

          <el-form-item label="TimeZone">
            <el-select
              v-model="currentProfileInfo.timeZoneId"
              style="width: 100%"
              class="TimeZone"
            >
              <template v-for="(item, index) in timZoneList">
                <el-option
                  :label="item.cityNameEn"
                  :value="item.id"
                  :key="index + 'time'"
                ></el-option>
              </template>
            </el-select>
          </el-form-item>

          <!-- <el-form-item label="Language">
            <el-select
              v-model="currentProfileInfo.language"
              style="width: 100%"
              class="language"
            >
              <el-option label="English" value="english"></el-option>
              <el-option label="Chinese" value="chinese"></el-option>
            </el-select>
          </el-form-item> -->

          <el-form-item label="Country">
            <el-select
              v-model="currentProfileInfo.nationality"
              placeholder="choose your country"
              style="width: 100%"
            >
              <template v-for="(item, index) in countryList">
                <el-option
                  :label="item.countryName"
                  :value="item.id"
                  :key="index + 'time'"
                ></el-option>
              </template>
            </el-select>
          </el-form-item>

          <el-form-item label="Password">
            <el-input
              v-model="currentProfileInfo.password"
              placeholder="*********"
              type="password"
            ></el-input>
          </el-form-item>

          <div class="button-area">
            <div class="cancle-btn btn font-menu-medium">Cancel</div>
            <div
              class="save-btn btn font-menu-medium"
              @click="submitPersonalInfo"
            >
              Save
            </div>
          </div>
        </el-form>
      </div>
      <div class="personal-avatar">
        <div class="avatar-box">
          <div class="avatar-content">
            <div class="avatar-img-wrapper">
              <span class="overlay"></span>

              <!-- <div class="avatar-img" @click="handleUpdateAvatar">
                <img :src="currentProfileInfo.photo" alt="" />
              </div> -->
              <el-upload
                class="avatar-img"
                :action="`${testUrl}/KidsUser/uploadKidsUserPieture`"
                :headers="headers"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload"
                :data="uploadData"
                :style="{
                  'background-image': 'url(' + currentProfileInfo.photo + ')',
                  'background-repeat': 'no-repeat',
                  'background-size': 'cover',
                  'background-position': 'center',
                }"
              >
                <img
                  style="visibilty: hidden"
                  :src="currentProfileInfo.photo"
                />
                <!-- <i v-else class="el-icon-plus avatar-uploader-icon"></i> -->
              </el-upload>
              <div class="open-camera ">
                <img src="@/assets/img/18-Account/icon-camera.svg" alt="" />
              </div>
            </div>
          </div>
          <div class="explain">Profile Picture</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getCountryList,
  getTimZoneList,
  updateKidsUserInfo,
  changeCurrentKid,
} from "@/assets/js/axios/kidsApi.js";

export default {
  name: "PersonalInformation",
  // props: {
  //   personalInfo: {
  //     type: Object,
  //     require: true,
  //   },
  // },

  data() {
    return {
      // testUrl: "https://testapi.nihaocafe.com/SmartLingo",
      testUrl: "https://api.nihaocafe.com/SmartLingo",
      headers: {},
      isFocus: false,
      currentProfileInfo: {},
      timZoneList: [],
      countryList: [],
    };
  },
  async created() {
    const { familyMember } = JSON.parse(localStorage.getItem("userData"));
    const patriarch = familyMember.filter((item) => item.isPatriarch);

    this.currentProfileInfo = patriarch[0];
    // console.log(patriarch[0]);

    const { token, refreshToken } = JSON.parse(
      localStorage.getItem("userData")
    );
    this.headers.token = token;
    this.headers.refreshToken = refreshToken;

    const resultTimeZone = await getTimZoneList();
    const resultCountry = await getCountryList();
    this.timZoneList = [...resultTimeZone.data];
    this.countryList = [...resultCountry.data];
    // console.log(this.currentProfileInfo);
  },
  computed: {
    kidUserId() {
      return this.$store.state.dashboard.kidUserId;
    },
    uploadData() {
      return { userId: this.currentProfileInfo.id };
    },
  },
  methods: {
    async submitPersonalInfo() {
      console.log("提交信息");
      const sendData = {
        userId: this.currentProfileInfo.id,
        firstName: this.currentProfileInfo.firstName,
        lastName: this.currentProfileInfo.lastName,
        photo: this.currentProfileInfo.photo,
        email: this.currentProfileInfo.email,
        phone: this.currentProfileInfo.phone,
        weChat: this.currentProfileInfo.weChat,
        timeZoneId: this.currentProfileInfo.timeZoneId,
        country: this.currentProfileInfo.nationality,
        password: this.currentProfileInfo.password,
        // language:'',
      };
      const result = await updateKidsUserInfo(sendData);
      console.log(result);
      if (result.code === 200) {
        this.$message({
          message: result.message,
          type: "success",
        });
        this.initUserProfile();
      } else {
        this.$message({
          message: result.message,
          type: "error",
        });
      }
    },
    async refreshUserData() {},
    handleAvatarSuccess(res, file) {
      console.log(URL.createObjectURL(file.raw));
      this.currentProfileInfo.photo = URL.createObjectURL(file.raw);
      // console.log(res);
      if (res.code === 200) {
        this.initUserProfile();
      }
    },
    async initUserProfile() {
      if (!this.$store.state.dashboard.isKid) {
        const sendData = {
          id: this.kidUserId,
        };
        const result = await changeCurrentKid(sendData);
        console.log(result);
        if (result && result.code === 200) {
          localStorage.userData = JSON.stringify(result.data);
          this.$store.commit("setUserInfo", result.data);
          window.location.reload();
        }
      }
    },
    // 图片校验
    beforeAvatarUpload(file) {
      // const isJPG = file.type === "image/jpeg";
      // const isLt2M = file.size / 1024 / 1024 < 2;
      // if (!isJPG) {
      //   this.$message.error("上传头像图片只能是 JPG 格式!");
      // }
      // if (!isLt2M) {
      //   this.$message.error("上传头像图片大小不能超过 2MB!");
      // }
      // return isJPG && isLt2M;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>
