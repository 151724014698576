var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "personal-information-container" }, [
    _c("div", { staticClass: "personal-info-area" }, [
      _c(
        "div",
        { staticClass: "personal-info font-menu-medium" },
        [
          _c(
            "el-form",
            {
              ref: "personalInfo",
              attrs: {
                model: _vm.currentProfileInfo,
                "label-width": "100px",
                "label-position": "left",
                placeholder: "Your first name",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "First Name" } },
                [
                  _c("el-input", {
                    staticClass: "name font-menu-medium",
                    model: {
                      value: _vm.currentProfileInfo.firstName,
                      callback: function ($$v) {
                        _vm.$set(_vm.currentProfileInfo, "firstName", $$v)
                      },
                      expression: "currentProfileInfo.firstName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "Last Name" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "Your last name" },
                    model: {
                      value: _vm.currentProfileInfo.lastName,
                      callback: function ($$v) {
                        _vm.$set(_vm.currentProfileInfo, "lastName", $$v)
                      },
                      expression: "currentProfileInfo.lastName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "Email" } },
                [
                  _c("el-input", {
                    staticClass: "email",
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.currentProfileInfo.email,
                      callback: function ($$v) {
                        _vm.$set(_vm.currentProfileInfo, "email", $$v)
                      },
                      expression: "currentProfileInfo.email",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "Phone" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "Your Phone Number" },
                    model: {
                      value: _vm.currentProfileInfo.phone,
                      callback: function ($$v) {
                        _vm.$set(_vm.currentProfileInfo, "phone", $$v)
                      },
                      expression: "currentProfileInfo.phone",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "Wechat" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "Your Wechat ID" },
                    model: {
                      value: _vm.currentProfileInfo.weChat,
                      callback: function ($$v) {
                        _vm.$set(_vm.currentProfileInfo, "weChat", $$v)
                      },
                      expression: "currentProfileInfo.weChat",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "TimeZone" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "TimeZone",
                      staticStyle: { width: "100%" },
                      model: {
                        value: _vm.currentProfileInfo.timeZoneId,
                        callback: function ($$v) {
                          _vm.$set(_vm.currentProfileInfo, "timeZoneId", $$v)
                        },
                        expression: "currentProfileInfo.timeZoneId",
                      },
                    },
                    [
                      _vm._l(_vm.timZoneList, function (item, index) {
                        return [
                          _c("el-option", {
                            key: index + "time",
                            attrs: { label: item.cityNameEn, value: item.id },
                          }),
                        ]
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "Country" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "choose your country" },
                      model: {
                        value: _vm.currentProfileInfo.nationality,
                        callback: function ($$v) {
                          _vm.$set(_vm.currentProfileInfo, "nationality", $$v)
                        },
                        expression: "currentProfileInfo.nationality",
                      },
                    },
                    [
                      _vm._l(_vm.countryList, function (item, index) {
                        return [
                          _c("el-option", {
                            key: index + "time",
                            attrs: { label: item.countryName, value: item.id },
                          }),
                        ]
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "Password" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "*********", type: "password" },
                    model: {
                      value: _vm.currentProfileInfo.password,
                      callback: function ($$v) {
                        _vm.$set(_vm.currentProfileInfo, "password", $$v)
                      },
                      expression: "currentProfileInfo.password",
                    },
                  }),
                ],
                1
              ),
              _c("div", { staticClass: "button-area" }, [
                _c("div", { staticClass: "cancle-btn btn font-menu-medium" }, [
                  _vm._v("Cancel"),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "save-btn btn font-menu-medium",
                    on: { click: _vm.submitPersonalInfo },
                  },
                  [_vm._v(" Save ")]
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "personal-avatar" }, [
        _c("div", { staticClass: "avatar-box" }, [
          _c("div", { staticClass: "avatar-content" }, [
            _c(
              "div",
              { staticClass: "avatar-img-wrapper" },
              [
                _c("span", { staticClass: "overlay" }),
                _c(
                  "el-upload",
                  {
                    staticClass: "avatar-img",
                    style: {
                      "background-image":
                        "url(" + _vm.currentProfileInfo.photo + ")",
                      "background-repeat": "no-repeat",
                      "background-size": "cover",
                      "background-position": "center",
                    },
                    attrs: {
                      action: _vm.testUrl + "/KidsUser/uploadKidsUserPieture",
                      headers: _vm.headers,
                      "on-success": _vm.handleAvatarSuccess,
                      "before-upload": _vm.beforeAvatarUpload,
                      data: _vm.uploadData,
                    },
                  },
                  [
                    _c("img", {
                      staticStyle: { visibilty: "hidden" },
                      attrs: { src: _vm.currentProfileInfo.photo },
                    }),
                  ]
                ),
                _vm._m(0),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "explain" }, [_vm._v("Profile Picture")]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "open-camera " }, [
      _c("img", {
        attrs: {
          src: require("@/assets/img/18-Account/icon-camera.svg"),
          alt: "",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }