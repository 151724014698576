<template>
  <div class="profile-container">
    <PageLayout>
      <template #page-name>
        <div class="nav-title font-menu-large">
          <div
            class="nav-title-item"
            v-for="(item, index) in navList"
            :key="index"
            :class="{ active: item.id === currentPage }"
            @click="changePage(item.id)"
          >
            {{ item.title }}
          </div>
        </div>
      </template>
      <template #page-content>
        <div class="content">
          <!-- <router-view></router-view> -->
          <PersonalInformation
            v-if="currentPage === 1 && !$store.state.dashboard.isKid"
          />
          <StudentProfile v-if="currentPage === 0" />
        </div>
      </template>
    </PageLayout>
  </div>
</template>

<script>
import PageLayout from "@/components/Page/Layout/PageLayout.vue";
import PersonalInformation from "@/views/Dashboard/Profile/PersonalInformation";
import StudentProfile from "@/views/Dashboard/Profile/StudentProfile";

export default {
  name: "Profile",
  components: {
    PageLayout,
    PersonalInformation,
    StudentProfile,
  },
  data() {
    return {
      currentPage: 1,
      navList: [
        {
          title: "Personal Information",
          id: 1,
        },
        {
          title: "Student Profile",
          id: 0,
        },
      ],
    };
  },
  created() {
    if (this.$store.state.dashboard.isKid) {
      this.navList.splice(0, 1);
      this.currentPage = 0;
      console.log(this.navList);
    }
  },

  methods: {
    changePage(index) {
      this.currentPage = index;
      // if (index) {
      //   window.location.hash = `#/dashboard/profile?userId=${this.userId}`;
      // } else {
      //   window.location.hash = `#/dashboard/profile?kids`;
      // }
    },
  },
};
</script>

<style lang="scss" scoped>
.profile-container {
  width: 100%;
  height: 100%;
  .active {
    color: #224e96;
  }

  .nav-title {
    display: flex;

    .nav-title-item {
      cursor: default;
      margin-right: 5%;
      position: relative;
      color: #9e9e9f;
      font-weight: 600;

      &:not(.active) {
        cursor: pointer;
      }
      &:hover {
        color: #224e96;
      }

      &.active {
        color: #224e96;
        &::after {
          position: absolute;
          content: "";
          animation: stretchAnimation 0.3s;
          background-color: #f4d737;
          height: 7px;

          left: 0;
          width: 60px;
          bottom: -12px;
        }
      }
    }
  }
  /deep/ .page-content {
    background: #fff;
    border-radius: 20px;
  }
  .content {
    width: 90%;
    height: 70vh;
    // height: 100%;
    padding: 2% 5%;
    background: #fff;
  }
}

.personal-info {
  .el-form-item__content {
    background-color: #fafafa;
  }
}
</style>
