var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "student-profile-container font-menu-medium" },
    [
      _c(
        "div",
        { staticClass: "family-avatar" },
        _vm._l(_vm.stuInfoList, function (item, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "avatar-item font-menu-medium",
              class: { activeBgc: index === _vm.currentId },
              on: {
                click: function ($event) {
                  return _vm.changeCurrentStu(index)
                },
              },
            },
            [
              !item.photo
                ? _c("div", { staticClass: "image" }, [
                    item.gender === 0
                      ? _c("img", {
                          attrs: {
                            src: require("@/assets/img/05-Panda/panda-boy.svg"),
                            alt: "",
                          },
                        })
                      : _c("img", {
                          attrs: {
                            src: require("@/assets/img/05-Panda/panda-girl.svg"),
                            alt: "",
                          },
                        }),
                  ])
                : _c("div", { staticClass: "image" }, [
                    _c("img", { attrs: { src: item.photo, alt: "" } }),
                  ]),
              _c("div", { staticClass: "name" }, [
                _vm._v(_vm._s(item.firstName)),
              ]),
            ]
          )
        }),
        0
      ),
      _c(
        "div",
        { staticClass: "student-data" },
        [
          _c(
            "el-form",
            {
              ref: "personalInfo",
              staticClass: "stu-form",
              attrs: {
                model: _vm.currentStuInfo,
                "label-width": "130px",
                "label-position": "left",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  staticClass: "font-menu-medium",
                  attrs: { label: "First Name" },
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: "Your first name" },
                    model: {
                      value: _vm.currentStuInfo.firstName,
                      callback: function ($$v) {
                        _vm.$set(_vm.currentStuInfo, "firstName", $$v)
                      },
                      expression: "currentStuInfo.firstName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "font-menu-medium",
                  attrs: { label: "Last Name" },
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: "Your last name" },
                    model: {
                      value: _vm.currentStuInfo.lastName,
                      callback: function ($$v) {
                        _vm.$set(_vm.currentStuInfo, "lastName", $$v)
                      },
                      expression: "currentStuInfo.lastName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "Birthday" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "date",
                      "range-separator": "",
                      "default-value": "",
                      "popper-class": "pick-date-range",
                    },
                    model: {
                      value: _vm.currentStuInfo.birthday,
                      callback: function ($$v) {
                        _vm.$set(_vm.currentStuInfo, "birthday", $$v)
                      },
                      expression: "currentStuInfo.birthday",
                    },
                  }),
                ],
                1
              ),
              _c("el-form-item", { attrs: { label: "Gender" } }, [
                _c("div", { staticClass: "gender-box" }, [
                  _c(
                    "div",
                    {
                      staticClass: "gender girl",
                      class: { opacity: _vm.currentStuInfo.gender === 1 },
                      on: {
                        click: function ($event) {
                          _vm.currentStuInfo.gender = 1
                        },
                      },
                    },
                    [_vm._v(" GIRL ")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "gender boy",
                      class: { opacity: _vm.currentStuInfo.gender === 0 },
                      on: {
                        click: function ($event) {
                          _vm.currentStuInfo.gender = 0
                        },
                      },
                    },
                    [_vm._v(" BOY ")]
                  ),
                ]),
              ]),
              _c(
                "el-form-item",
                { attrs: { label: "Special Needs" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      autosize: { minRows: 3, maxRows: 4 },
                      placeholder: "Type...",
                    },
                    model: {
                      value: _vm.currentStuInfo.specialNeeds,
                      callback: function ($$v) {
                        _vm.$set(_vm.currentStuInfo, "specialNeeds", $$v)
                      },
                      expression: "currentStuInfo.specialNeeds",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("div", { staticClass: "stu-avatar" }, [
            _c("div", { staticClass: "avatar-box" }, [
              _c("div", { staticClass: "avatar-content" }, [
                _c(
                  "div",
                  { staticClass: "avatar-img-wrapper" },
                  [
                    _c("span", { staticClass: "overlay" }),
                    !_vm.currentStuInfo.photo
                      ? _c("div", { staticClass: "avatar-img" }, [
                          _vm.currentGender === 0
                            ? _c("img", {
                                attrs: {
                                  src: require("@/assets/img/05-Panda/panda-boy.svg"),
                                  alt: "",
                                },
                              })
                            : _c("img", {
                                attrs: {
                                  src: require("@/assets/img/05-Panda/panda-girl.svg"),
                                  alt: "",
                                },
                              }),
                        ])
                      : _vm._e(),
                    _c(
                      "el-upload",
                      {
                        staticClass: "avatar-img",
                        attrs: {
                          action:
                            _vm.testUrl + "/KidsUser/uploadKidsUserPieture",
                          headers: _vm.headers,
                          "on-success": _vm.handleAvatarSuccess,
                          data: _vm.uploadData,
                          "before-upload": _vm.beforeAvatarUpload,
                        },
                      },
                      [_c("img", { attrs: { src: _vm.currentStuInfo.photo } })]
                    ),
                    _vm._m(0),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "explain" }, [
                _vm._v("Profile Picture"),
              ]),
            ]),
            _c(
              "div",
              {
                staticClass: "gender-flag",
                class: { "girl-flag": _vm.currentGender === 1 },
              },
              [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.currentGender === 0,
                        expression: "currentGender === 0",
                      },
                    ],
                    staticClass: "boy",
                  },
                  [_vm._v("BOY")]
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.currentGender === 1,
                        expression: "currentGender === 1",
                      },
                    ],
                    staticClass: "girl",
                  },
                  [_vm._v("GIRL")]
                ),
              ]
            ),
          ]),
        ],
        1
      ),
      _c("div", { staticClass: "button-area font-menu-medium" }, [
        _c("div", { staticClass: "cancle-btn btn" }, [_vm._v("Cancel")]),
        _c(
          "div",
          {
            staticClass: "save-btn btn",
            on: { click: _vm.submitPersonalInfo },
          },
          [_vm._v("Save")]
        ),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "open-camera" }, [
      _c("span", { staticClass: "overlay" }),
      _c("img", {
        attrs: {
          src: require("@/assets/img/18-Account/icon-camera.svg"),
          alt: "",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }